import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
	Box,
	Chip,
	ClickAwayListener,
	FormControl,
	Grow,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	SvgIcon,
	Select,
	TextField,
	Tooltip,
	Typography,
	makeStyles,
	Collapse,
} from '@material-ui/core';
import Label from '../../../components/Label';
import {
	Search as SearchIcon,
	X as XIcon
} from 'react-feather';
import { logicService, storageService } from './services';
import { OPERATORS_LIST } from './config';

const useStyles = makeStyles(theme => ({
	root: {
		flex: '1 1 auto'
	},
	chip: {
		maxWidth: 300,
		marginRight: theme.spacing(1)
	},
	typeLabel: {
		marginRight: theme.spacing(1),
		minWidth: 'fit-content',
		height: 30
	},
	dropDown: {
		zIndex: theme.zIndex.drawer,
		minWidth: 150,
		maxWidth: 250
	},
	startAdornment: {
		display: 'contents',
		alignItems: 'center',
		marginRight: theme.spacing(1)
	},
}));

function SearchBoxComponent({ 
	searchParams,
	name,
	handleChange
}) {
	const classes = useStyles();
	const anchorRef = useRef(null);
	const startAdornmentRef = useRef(null);

	const [searchArray, setSearchArray] = useState(logicService.getDefaultValue(name, searchParams, searchArray, "searchArray") || new Array());

	
	const [init, setInit] = useState(logicService.getDefaultValue(name, searchParams, searchArray, "type") === "text" ? false : true );
	const [menuOpen, setMenuOpen] = useState(false);
	const [inputTimer, setInputTimer] = useState(null);
	
	const [property, setProperty] = useState(logicService.getDefaultValue(name, searchParams, searchArray, "property") || null);
	const [operator, setOperator] = useState(logicService.getDefaultValue(name, searchParams, searchArray, "operator") || null);
	const [value, setValue] = useState(logicService.getDefaultValue(name, searchParams, searchArray, 'value') || null);

	function getMenuItems() {
		let items;
		
		// visualizzo le property non selezionate
		if (!property) {
			// ordino le proprietà a-z poi pipeline a-z
			searchParams.sort((a, b) => {
				if (a.label < b.label) return -1;
				if (a.label > b.label) return 1;
				return 0;
			});

			items = searchParams.map(x => {
				if (
					x.type === 'pipeline' ||
					!searchArray.find(y => y.property.name === x.name)
				)
					if (
						x.type !== 'pipeline' ||
						!searchArray.find(y => y.property.type === 'pipeline')
					)
						return (
							<MenuItem
								id="menuItem"
								key={x.name}
								onClick={() => {
									setProperty(x);
								}}
							>
								{x.label}
							</MenuItem>
						);
			});
		}
		// visualizzo gli operatori associati al tipo di proprietà
		else if (property && !operator) {
			if(property?.customOperators) {
				items = property.customOperators.map(x => {
					let _operator = OPERATORS_LIST.find(y => y.name === x);

					return (
						<MenuItem
							id="menuItem"
							key={_operator.value}
							onClick={() => {
								setOperator(_operator);

								switch (property.type) {
									case 'text':
									case 'date':
									case 'number':
										document.getElementById("searchBox").focus();
										setMenuOpen(false);
										break;
									case 'enum':
									case 'pipeline':
										getMenuItems();
										break;
								}
							}}
						>
							{_operator.label}
						</MenuItem>
					);

				})
			}
			else {
				items = OPERATORS_LIST.map(x => {
					if (x.propertyTypes.includes(property.type))
						return (
							<MenuItem
								id="menuItem"
								key={x.value}
								onClick={() => {
									setOperator(x);

									switch (property.type) {
										case 'text':
										case 'date':
										case 'number':
											document.getElementById("searchBox").focus();
											setMenuOpen(false);
											break;
										case 'enum':
										case 'pipeline':
											getMenuItems();
											break;
									}
								}}
							>
								{x.label}
							</MenuItem>
						);
				});
			}
			
		} 
		// visualizzo i values degli enum
		else if (property && property.values && operator) {
			items = property.values.map(x => (
				<MenuItem
					id="menuItem"
					key={x.code}
					onClick={() => {
						setValue(x);
						setMenuOpen(false);
					}}
				>
					{x.label}
				</MenuItem>
			));
		}

		return items;
	}

	function addQueryParam(event) {
		if (event) event.preventDefault();
		
		if(property && operator && value !== '') {
			let array = searchArray;
			
			array.push({
				property: property,
				operator: operator,
				value: value
			});
			
			setSearchArray(array);
			
			setProperty(null);
			setOperator(null);
			setValue('');
		}

	}
	
	function removeQueryParam(removedKey) {
		let array = searchArray.filter(s => {
			if (s.property.name !== removedKey) return s;
		})
		
		setSearchArray(array);

	}
	
	function submit(array, bypass) {
		if(init || bypass){
			let query = new Object;
			
			array.forEach(s => {
				if(s.type === 'pipeline') {
					query['pipeline'] = s.name;
					query['pipeline_status'] = s.value.code;
				} else {
					query[s.property.name] = typeof s.value === 'string' ? s.value : s.value?.code;
					query[`${s.property.name}.operator`] = s.operator.name;		
				}
			});
			
			handleChange(query);
		}
	}

	// GESTISCO STORAGE
	useEffect(() => {
		if (searchArray.length > 0) {
			let store = storageService.get(name);
			let storeArray = store?.[name]?.searchArray ? JSON.parse(store?.[name]?.searchArray) : [];
			let array = searchArray;

			storageService.set(name, 'searchArray', searchArray);
			
			if ((!storeArray || storeArray?.length < array.length) && array[array.length - 1]?.property?.type === 'text')
				return;
			else
				submit(searchArray, true);
		}

		if(searchArray.length === 0) {
			let store = storageService.get(name);

			if(store?.[name]?.searchArray?.length > searchArray?.length) {
				storageService.set(name, 'searchArray', null)
				submit(searchArray, true);
			}
		}
	}, [searchArray.length]);

	useEffect(() => {
		if (searchArray.find(x => x?.property?.name === property?.name)) {
			storageService.set(name, "property", null);
			storageService.set(name, "operator", null);
		} else {
			if (property && operator || (!property && !operator)) {
				storageService.set(name, "property", property);
				storageService.set(name, "operator", operator);
			}
		}
	}, [property, operator]);

	// GESTISCE CAMBIO INPUT VALUE
	useEffect(() => {
		if (property && (property.type === 'enum' || property.type === 'pipeline') && operator && value !== '')
			addQueryParam();
		else if (property && (property.type !== 'enum' && property.type !== 'pipeline') && operator && (value || value == '') ) {
			clearTimeout(inputTimer);
			
			setInputTimer(
				setTimeout(() => {
					let array = [
						...searchArray,
						{
							property: property,
							operator: operator,
							value: value
						}
					];

					submit(array);
				}, 2000) // millisecondi
			);
		}
	}, [ value ])

	return (
		<div className={classes.root}>
			<form onSubmit={addQueryParam} autoComplete="off" onChange={() => setInit(true)}>
				<TextField
					id="searchBox"
					fullWidth
					autoComplete="off"
					label="Cerca"
					value={typeof value === 'string' ? value : value?.label}
					variant="outlined"
					type={
						(property && property.type === 'date' && operator)
							? 'date'
							: (property && property.type === 'number' && operator)
							? 'number'
							: 'text'
					}
					onClick={() => {
						if (!property || !operator) setMenuOpen(true);
						if (property && (property.type === 'enum' || property.type === 'pipeline')  && !value) 
							setMenuOpen(true);
					}}
					onKeyDown={event => {
						let keyCode = event.keyCode;
						let key = event.key;

						if (keyCode === 8 || key === 'Backspace')
							if (value === '') {
								setInit(true);

								if (operator) setOperator(null);
								else if (property) setProperty(null);
							}
					}}
					onKeyUp={event => {
						let keyCode = event.keyCode;
						let key = event.key;

						if (keyCode === 13 || key === 'Enter')
							if (value !== '') addQueryParam();
					}}
					onChange={event => {
						let newValue = event.target.value;

						if (property && operator) {
							setValue(newValue);
						}
					}}
					InputProps={{
						startAdornment: (
							<Box ref={startAdornmentRef} className={classes.startAdornment}>
								<InputAdornment position="start">
									<SvgIcon
										fontSize="small"
										color="action"
										style={{ marginRight: '8px' }}
									>
										<SearchIcon />
									</SvgIcon>
								</InputAdornment>

								{searchArray.map(s => (
									<Tooltip
										arrow
										title={
											<Typography variant="body1" color="textPrimary">
												{s.property.type === 'date'
													? moment(s.value).format('DD/MM/YYYY')
													: typeof s.value === 'string'
													? s.value
													: s.value?.label}
											</Typography>
										}
										key={typeof s.value === 'string' ? s.value : s.value?.label}
									>
										<Chip
											label={`${s?.property?.label} ${s.operator.value} ${
												s.property.type === 'date'
													? moment(s.value).format('DD/MM/YYYY')
													: typeof s?.value === 'string'
													? s.value
													: s.value?.label
											}`}
											color="primary"
											onDelete={() => removeQueryParam(s.property.name)}
											className={classes.chip}
										/>
									</Tooltip>
								))}

								{property && (
									<Label className={classes.typeLabel}>
										<Typography variant="body1" color="textPrimary">
											{property.label}
										</Typography>
									</Label>
								)}

								{operator && (
									<Label className={classes.typeLabel}>
										<Typography variant="body1" color="textPrimary">
											{operator.value}
										</Typography>
									</Label>
								)}

								{/* TAG VUOTO PER ANCHOR DROPDOWN */}
								<Typography variant="body1" color="textPrimary" ref={anchorRef}>
									&nbsp;
								</Typography>
							</Box>
						),
						endAdornment: (
							<IconButton
								onClick={() => {
									setProperty(null);
									setOperator(null);
									setValue('');
									setSearchArray(new Array());
									storageService.clear(name);
									submit([], true);
								}}
							>
								<XIcon />
							</IconButton>
						),
						readOnly: property?.type === 'enum'
					}}
				/>

				{/* MENU' */}
				<Collapse>
					<Select
						open={menuOpen}
						onClose={event => {
							if(event.target.id !== "menuItem"){
								setMenuOpen(false);
							} 
						}}
					>
						{getMenuItems()}
					</Select>		
				</Collapse>	
			</form>
		</div>
	);
}

SearchBoxComponent.propTypes = {
	name: PropTypes.string,
	searchParams: PropTypes.array.isRequired,
	handleChange: PropTypes.func.isRequired,
	className: PropTypes.string
};

export default SearchBoxComponent;